<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.OrderDate | display_date('DD-MM-YYYY')}}</td>
                            <td class="text-end" v-if="adminRole">
                                <v-edit-dialog :return-value.sync="item.OrderAmount" large lazy persistent @save="saveItem({'ID': item.ID, 'OrderAmount': item.OrderAmount})">
                                    <div>{{ item.OrderAmount | currency('¥',2)}}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.OrderAmount" type="number" step=".2" label="Tiền hoàn lại" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end" v-if="!adminRole">{{ item.OrderAmount | currency('¥',2)}}</td>
                            <td class="text-end" v-if="adminRole">
                                <v-edit-dialog :return-value.sync="item.OrderCode" large lazy persistent @save="saveItem({'ID': item.ID, 'OrderCode': item.OrderCode})">
                                    <div>{{ item.OrderCode }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.OrderCode" label="Mã đơn hàng TQ" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end" v-if="!adminRole">{{ item.OrderCode }}</td>
                            <td><a target="blank" :href="'/don-hang/' + item.OrderID">{{ item.OrderID ? 'MD'+item.OrderID : ''}}</a></td>
                            <td>{{ item.Username }}</td>
                            <td v-bind:style="{ 'color': get_type_color(item.Status, tuikuan_status_list)}" v-if="adminRole">
                                <v-edit-dialog :return-value.sync="item.Status" large lazy persistent @save="saveItem({'ID': item.ID, 'Status': item.Status})">
                                    <div>{{ item.Status | display_value(tuikuan_status_list) }}</div>
                                    <template v-slot:input>
                                        <v-select :items="tuikuan_status_list" v-model="item.Status" item-text="name" item-value="value"></v-select>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td v-bind:style="{ 'color': get_type_color(item.Status, tuikuan_status_list)}" v-if="!adminRole">{{ item.Status | display_value(tuikuan_status_list) }}</td>
                            <td class="truncate" v-if="adminRole || paymentRole">
                                <v-edit-dialog :return-value.sync="item.Note" large lazy persistent @save="saveItem({'ID': item.ID, 'Note': item.Note})">
                                    <v-tooltip top right max-width="250">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ item.Note }}</span>
                                        </template>
                                        <span>{{ item.Note }}</span>
                                    </v-tooltip>
                                    <template v-slot:input>
                                        <v-textarea v-model="item.Note" label="Ghi chú" single-line autofocus></v-textarea>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="truncate" v-if="!adminRole && !paymentRole">{{ item.Note }}</td>
                            <td class="text-center">
                                <v-icon v-if="adminRole" medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append>
                        <tr class="total-summary-td">
                            <td colspan="1" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ (summary ? summary.OrderAmount : 0) | currency('', 2)}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        return {
            headers: [
                {
                    text: "Ngày",
                    value: "OrderDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Số tiền",
                    value: "OrderAmount",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Mã đơn hàng TQ",
                    value: "OrderCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Mã đơn hàng",
                    value: "OrderID",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Khách hàng",
                    value: "Username",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    values: configs.TUIKUAN_STATUS_LIST,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Ghi chú",
                    value: "Note",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center"
                }
            ],
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "tuikuan_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
            tuikuan_status_enum: configs.TUIKUAN_STATUS,
            tuikuan_status_list: configs.TUIKUAN_STATUS_LIST,
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.tuikuan.all.data,
            total_rows: state => state.tuikuan.all.total,
            summary: state => state.tuikuan.all.summary,
            loading: state => state.tuikuan.loading
        }),
        tableConfig() {
            return {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
    },
    methods: {
        filter_data: function () {
            this.$store.dispatch("tuikuan/getList", this.tableConfig);
        },
        gotoDetail: function (id) {
            this.$router.push({
                name: "TuiKuanDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết hoàn tiền (#${id})`
                }
            });
        },
        deleteItem: function(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$router.push({
                name: "TuiKuanDetail",
                params: {
                Pid: 'tao-moi',
                title: `Tạo mới hoàn tiền`
                }
            });
        },
        saveItem(obj) {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("tuikuan/setDetail", {param: this.tableConfig, data: obj, id: obj.ID});
        },
        get_type_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("tuikuan/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
